import React from 'react';
import Portrait from './components/portrait';

function App() {
  return (
    <div className="App">
      <div className="max-w-2xl	m-auto">
        <Portrait />

        <div className="p-4 md:p-0 max-w-portrait">
        <h1>Hey there,</h1>
          <p>my name is <strong>Michael.</strong> I know a lot about creating, optimizing and maintaining websites of any type and scale.</p>
          <p>I am currently building a team at <b><a href="https://www.dehner.de" target="_blank" rel="noopener noreferrer">Dehner</a></b> to run and improve our digital commerce techstack around <b>Spryker</b> and <b>SAP</b>.<br /></p>
          <p><i>Wanna talk?</i> Find <a href="https://www.linkedin.com/in/michael-gei%C3%9Fler-676664116/" target="_blank" rel="noopener noreferrer">me on LinkedIn</a> or send me an <a href="mailto:michael@soulstyled.de" rel="noopener noreferrer">email</a>.</p>
        </div>

      </div>
    </div>
  );
}

export default App;
